@import "../../../styles/variables";

.consumer-info {
  overflow-x: auto;
  white-space: nowrap;
  padding-left: 20px!important;
  padding-right: 20px!important;
}

@media print {
  [class*="overflow-hidden"] {
    overflow: visible !important;
  }
  [class*="overflow-auto"] {
    overflow: visible !important;
  }
  .printAutoHW {
    width: auto !important;
    height: auto !important;
    max-width: none !important;
    max-height: none !important;
    min-width: 0 !important;
    min-height: 0 !important;
  }
}

.reset {
  * {
    font-family: sans-serif !important;
    font-size: 14px !important;
    // line-height: 1.6 !important;
  }
  p {
    margin: 0 !important;
    white-space: pre-line;
  }
  div > span {
    white-space: pre-line;
  }
  *:empty {
    display: none !important;
  }
  *:last-child {
    margin-bottom: 0;
  }
}


.reset-md {
  // background-color: #2f2f2f;
  // color: #fff;

  * {
    font-family: sans-serif !important;
    font-size: 14px !important;
    // line-height: 1.6 !important;
  }
  p {
    margin: 0 !important;
    white-space: pre-line;
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    margin: 0 !important;
  }
  h1 {
    text-align: center;
    margin-bottom: 0.5rem !important;
  }
  div > span {
    white-space: pre-line;
  }
  * + ul {
    // margin-top: -0.5rem !important;
  }
  *:empty {
    display: none !important;
  }
  *:last-child {
    margin-bottom: 0;
  }
}
